/** @format */

import * as React from 'react';
import { DateTime } from 'luxon';
import * as _ from 'underscore';
import validator from '@rjsf/validator-ajv8';

import Form from '@rjsf/mui';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { GridCustomNotes } from '../common/react-json-schema/GridCustomNotes';
import { GridCheckBoxField } from '../common/react-json-schema/GridCheckBoxField';
import { GridInputRadioField } from '../common/react-json-schema/GridInputRadioField';
import { GridObjectFieldTemplateWithoutCard } from '../common/react-json-schema/GridObjectFieldTemplate';
import { TitleFieldTemplate, FieldErrorTemplate, GridSelectInputField, GridInputField } from '../common/react-json-schema';

import { AppointmentsType } from '../../types/Appointments';

import { convertToRawText } from '../../utils/functions/notes';

import { APPOINTMENT_PLACES_LIST, DAYS_BEFORE_CONSULTATION_OPTIONS, REASONS_FOR_VISIT_OPTIONS } from '../../constants/appointments';

// Css
import './SchedulePatientAppointmentForm.scss';

interface SchedulePatientAppointmentFormProps {
  isUpdatingAppointment: boolean;

  disableButtons: boolean;

  disableAppointmentSelectType?: boolean;

  isEditMode: boolean;
  handleClickEditBtn: () => void;

  appointment?: AppointmentsType;

  handleClickCanelBtn: () => void;
  handleSubmitAppointment: (appointment: AppointmentsType) => void;

  handleDeleteAppointment: (appointment: AppointmentsType) => void;

  selectedPatientInfo?: any;
}

const SchedulePatientAppointmentForm: React.FunctionComponent<SchedulePatientAppointmentFormProps> = (props) => {
  const formDataRef: any = React.useRef(null);

  const [appointmentFormdata, setAppointmentFormdata] = React.useState({ appointmentPlace: null as any, isLabTest: false, daysBeforeConsultation: undefined, notes: [] });

  React.useEffect(() => {
    setAppointmentFormdata(formDataRef.current);
  }, [props.selectedPatientInfo]);

  const customValidate = (formData: any, errors: any) => {
    if (!formData.date || isNaN(Date.parse(DateTime.fromJSDate(new Date(formData?.date)).toISO() as string))) {
      errors.date.addError('Please enter a valid date');
    }

    if (!formData.date || isNaN(Date.parse(DateTime.fromJSDate(new Date(formData?.date)).toISO() as string))) {
      errors.date.addError('Please enter a valid date');
    }

    if (formData.date) {
      const diff = DateTime.fromJSDate(new Date(formData.date)).startOf('day').diff(DateTime.local().startOf('day'), 'days').toObject()?.days!;

      if (diff < 0) {
        errors.date.addError('Appointment Date cannot be in past');
      } else if (diff > 30 * 3) {
        errors.date.addError('Appointment Date should be less than 3 months');
      }
    }

    if (formData.startTime) {
      const startTime = DateTime.fromJSDate(new Date(`${formData.date} ${formData.startTime}`));

      if (startTime.toMillis() < DateTime.now().toMillis()) {
        errors.startTime.addError('Start time should be greater than current time');
      }
    }

    if (formData.startTime && formData.endTime) {
      const startTime = DateTime.fromJSDate(new Date(`${formData.date} ${formData.startTime}`));
      const endTime = DateTime.fromJSDate(new Date(`${formData.date} ${formData.endTime}`));

      if (startTime.toMillis() === endTime.toMillis()) {
        errors.startTime.addError('Start time should not be same as end time');
      }
    }

    if (formData.appointmentType && formData.appointmentType === 'CONSULTATION') {
      if (!formData.appointmentPlace) {
        errors.appointmentPlace.addError('Please select');
      }

      if (!formData.startTime) {
        errors.startTime.addError('Please enter a start time');
      }

      if (!formData.endTime) {
        errors.startTime.addError('Please enter a endTime time');
      }

      if (
        formData.endTime &&
        formData.startTime &&
        DateTime.fromJSDate(new Date(`${formData.date} ${formData.startTime}`)).toMillis() > DateTime.fromJSDate(new Date(`${formData.date} ${formData.endTime}`)).toMillis()
      ) {
        errors.startTime.addError('Start time cannot be greater than end time');
      }
    }

    if (formData.isLabTest && !formData.daysBeforeConsultation) {
      errors.daysBeforeConsultation.addError('Please select');
    }

    return errors;
  };

  const transformErrors = (errors: any[]) => {
    return errors.map((error) => {
      const path = error.property.split('.');
      const fieldName = path[path?.length - 1] || '';

      const formatFieldName: string = fieldName.replace(/([A-Z])/g, ' $1').replace(/^./, (str: string) => str.toUpperCase());

      if (error.name === 'type') {
        error.message = `${formatFieldName} cannot be empty`;
      }

      if (error.name === 'pattern') {
        error.message = `Please enter valid ${formatFieldName}`;
      }

      if (error.name === 'minLength') {
        error.message = `${formatFieldName} should be minimum ${error?.params?.limit} char`;
      }

      if (error.name === 'maxLength') {
        error.message = `${formatFieldName} should not exceed ${error?.params?.limit} char`;
      }

      if (error.name === 'maximum') {
        error.message = `${formatFieldName} should be less or equal to ${error?.params?.limit}`;
      }

      if (error.name === 'minimum') {
        error.message = `${formatFieldName} should be  greater or equal to ${error?.params?.limit}`;
      }

      if (error.name === 'required') {
        error.message = `Other ${formatFieldName} is required`;
      }

      if (error.message.includes('patientRules.rules')) {
        error.message = 'Invalid Medication rule.';
      }

      return error;
    });
  };

  const onChangeForm = (data: any, id: string) => {
    formDataRef.current = data;
  };

  const _renderSubmitButton = () => {
    const appointmentDate = DateTime.fromJSDate(new Date(props?.appointment?.startDate as string)).toISO();
    const currentDate = DateTime.now().toISO();
    // if (props.appointment && 'isEditable' in props.appointment && !props.appointment?.isEditable) {
    //   return <></>;
    // }

    if (props.appointment && props.appointment.appointmentId && appointmentDate! < currentDate) {
      return <></>;
    }

    if (!props.isEditMode) {
      return (
        <Grid display='flex' alignItems='center' justifyContent='flex-end' ml='auto'>
          <Grid item marginRight={2}>
            <Button
              disabled={props.disableButtons}
              disableRipple
              disableElevation
              size='large'
              variant='outlined'
              color='error'
              startIcon={<DeleteIcon />}
              onClick={() => props.handleDeleteAppointment(props.appointment!)}>
              <Typography variant='fontSemiBold16'>Delete</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button disabled={props.disableButtons} disableRipple disableElevation size='large' variant='outlined' startIcon={<EditIcon />} onClick={props.handleClickEditBtn}>
              <Typography variant='fontSemiBold16'>Edit</Typography>
            </Button>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid display='flex' alignItems='center' justifyContent='flex-end' ml='auto'>
        <Grid item marginRight={2}>
          <Button disableRipple disabled={props.disableButtons} disableElevation size='large' variant='outlined' onClick={props.handleClickCanelBtn}>
            <Typography variant='fontSemiBold16'>Cancel</Typography>
          </Button>
        </Grid>
        <Grid item>
          <LoadingButton
            size='large'
            type='submit'
            loading={props.isUpdatingAppointment}
            loadingPosition='end'
            variant='contained'
            disabled={props.isUpdatingAppointment || props.disableButtons}
            color='primary'
            disableRipple
            disableElevation>
            <Typography variant='fontSemiBold16'>Save</Typography>
          </LoadingButton>
        </Grid>
      </Grid>
    );
  };

  const onSubmit = async ({ formData }: any, event: any) => {
    const newStartDate = new Date(`${formData.date} ${formData.startTime || '07:00:00'}`).toISOString();
    const newEndDate = new Date(`${formData.date} ${formData.endTime || '18:59:59'}`).toISOString();

    const payload: AppointmentsType = {
      startDate: formData.appointmentType === 'CONSULTATION' ? newStartDate : (DateTime.fromISO(newStartDate).set({ hour: 7, minute: 0, second: 0 }).toISO() as string),
      endDate: formData.appointmentType === 'CONSULTATION' ? newEndDate : (DateTime.fromISO(newStartDate).set({ hour: 18, minute: 59, second: 59 }).toISO() as string),

      appointmentId: props.appointment?.appointmentId,

      patientDetails: props.appointment?.patientDetails || ({} as any),

      appointmentPlace: formData.appointmentPlace,
      appointmentType: formData.appointmentType,

      isDeleted: false,
      isEditable: true,

      status: props.appointment?.status,
      additionalProperties: [] as any,
    };

    if (!_.isEmpty(formData.notes)) {
      const rawText = !_.isEmpty(formData.notes) ? convertToRawText(formData.notes) : '';

      payload?.additionalProperties?.push({
        type: 'NOTES',
        payload: {
          payload: formData.notes,
          rawText: rawText || '',
        },
        referenceId: props.appointment?.additionalProperties?.find((e) => e.type === 'NOTES')?.referenceId || undefined,
      });
    }

    if (formData.isLabTest) {
      payload?.additionalProperties?.push({
        type: 'LAB_TEST',
        isLabTest: formData.isLabTest,
        daysBeforeConsultation: formData.daysBeforeConsultation,
        referenceId: props.appointment?.additionalProperties?.find((e) => e.type === 'LAB_TEST')?.referenceId || undefined,
      });
    } else if (formData.isLabTest === false) {
      payload?.additionalProperties?.push({
        type: 'LAB_TEST',
        isLabTest: false,
        referenceId: props.appointment?.additionalProperties?.find((e) => e.type === 'LAB_TEST')?.referenceId || undefined,
      });
    }

    props.handleSubmitAppointment(payload);
  };

  React.useEffect(() => {
    const notes = props.appointment?.additionalProperties?.find((item) => item.type === 'NOTES');
    const labTestPayload = props.appointment?.additionalProperties?.find((item) => item.type === 'LAB_TEST');
    const formData = {
      appointmentType: props.appointment?.appointmentType || 'CONSULTATION',
      appointmentPlace: props.appointment?.appointmentPlace || 'PHYSICAL',
      date: props.appointment?.startDate ? DateTime.fromJSDate(new Date(props.appointment?.startDate)).toISODate() : DateTime.local().toISODate(),

      startTime: props.appointment?.startDate
        ? DateTime.fromJSDate(new Date(props.appointment?.startDate)).toFormat('HH:mm:ss')
        : DateTime.local().plus({ hours: 1 }).startOf('hour').toFormat('HH:mm'),
      endTime: props.appointment?.endDate ? DateTime.fromJSDate(new Date(props.appointment?.endDate)).toFormat('HH:mm:ss') : DateTime.local().plus({ hours: 1 }).endOf('hour').toFormat('HH:mm'),

      isLabTest: labTestPayload?.isLabTest || false,

      daysBeforeConsultation: labTestPayload?.daysBeforeConsultation ? `${labTestPayload?.daysBeforeConsultation}` : undefined,

      notes: !_.isEmpty(notes?.payload) ? (Array.isArray(notes?.payload) ? notes?.payload : notes?.payload?.payload || []) : [],
    };

    formDataRef.current = formData;
    setAppointmentFormdata(formData as any);
  }, [props.appointment]);

  const schema: any = {
    type: 'object',
    required: ['appointmentType', 'date'],
    properties: {
      appointmentType: {
        title: 'Reason For Visit',
        type: 'string',
        enum: ['CONSULTATION', 'LAB_TEST'],
        default: 'CONSULTATION',
      },

      date: {
        title: 'Date',
        type: 'string',
      },

      notes: {
        title: 'Visit Notes',
        type: 'array',
      },
    },

    dependencies: {
      appointmentType: {
        oneOf: [
          {
            properties: {
              appointmentType: {
                enum: ['CONSULTATION'],
              },
              date: {
                type: 'string',
              },
              appointmentPlace: {
                title: 'Appointment Place',
                type: 'string',
                default: 'PHYSICAL',
              },
              startTime: {
                title: 'Start Time',
                type: 'string',
              },
              endTime: {
                title: 'End Time',
                type: 'string',
              },
              isLabTest: {
                title: 'Lab Test',
                type: 'boolean',
              },
            },
          },
        ],
        allOf: [
          {
            if: {
              type: 'object',
              properties: {
                isLabTest: {
                  const: true,
                },
                appointmentType: {
                  const: 'CONSULTATION',
                },
              },
            },

            then: {
              type: 'object',
              properties: {
                daysBeforeConsultation: { type: 'string', title: 'Days Before Consultation' },
              },
            },
          },
        ],
      },
    },
  };

  const uiSchema: any = {
    'ui:order': ['appointmentType', 'date', '*', 'daysBeforeConsultation', 'notes'],

    'ui:ObjectFieldTemplate': GridObjectFieldTemplateWithoutCard,

    appointmentType: {
      'ui:widget': GridSelectInputField,
      'ui:customOptions': REASONS_FOR_VISIT_OPTIONS,
      'ui:disabled': props.disableAppointmentSelectType,
      'ui:grid': {
        className: 'appointment-type-grid-select-input-field',
        xs: 12,
      },
    },

    date: {
      'ui:widget': GridInputField,
      'ui:type': 'date',
      'ui:grid': {
        className: 'date-grid-input-field',
        xs: 12,
      },
    },

    startTime: {
      'ui:widget': GridInputField,
      'ui:type': 'time',
      'ui:required': true,
      'ui:grid': {
        className: 'start-time-grid-input-field',
        xs: 12,
      },
    },

    endTime: {
      'ui:widget': GridInputField,
      'ui:type': 'time',
      'ui:required': true,
      'ui:grid': {
        className: 'end-time-grid-input-field',
        xs: 12,
      },
    },

    appointmentPlace: {
      'ui:widget': GridInputRadioField,
      'ui:raidoDisplayRow': true,
      'ui:required': true,
      'ui:customOptions': APPOINTMENT_PLACES_LIST,
      'ui:grid': {
        className: 'appointment-place-grid-input-field',
        xs: 12,
      },
    },

    notes: {
      'ui:field': GridCustomNotes,
      'ui:grid': {
        className: 'notes-grid-custom-notes',
        xs: 12,
      },
    },

    isLabTest: {
      'ui:widget': GridCheckBoxField,
      'ui:grid': {
        className: 'is-lab-test-grid-checkbox-field',
      },
      // 'ui:disabled': !!props.appointment?.appointmentId,
    },

    daysBeforeConsultation: {
      'ui:widget': GridSelectInputField,
      'ui:customOptions': DAYS_BEFORE_CONSULTATION_OPTIONS,
      'ui:grid': {
        className: 'days-before-consultation-grid-select-input-field',
      },
    },
  };

  return (
    <Box id='appointment-item-container'>
      <Form
        disabled={!props.isEditMode}
        schema={schema}
        uiSchema={uiSchema}
        validator={validator}
        templates={{ TitleFieldTemplate, FieldErrorTemplate }}
        formData={appointmentFormdata}
        customValidate={customValidate}
        transformErrors={transformErrors}
        showErrorList={false}
        onError={console.error}
        onSubmit={onSubmit}
        onChange={(data: any, id: any) => onChangeForm(data.formData, id)}>
        {_renderSubmitButton()}
      </Form>
    </Box>
  );
};

export default SchedulePatientAppointmentForm;
