/** @format */

import React from 'react';
import { Box, Chip, FormControl, IconButton, Input, InputAdornment, ListItemIcon, ListItemText, Menu, MenuItem, Select, Typography } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
// import RemoveIcon from '@mui/icons-material/Remove';
import RestoreIcon from '@mui/icons-material/Restore';
// import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

import { DropdownOptionType } from '../../../types/CustomForm.types';
export interface MultiInputInterface {
  type: 'select' | 'input';
  label: string | null;
  value: string;
  options?: DropdownOptionType[];
  onChange: (payload: string) => void;
  inputType?: string;
  disableAutoEnter?: boolean;
  defaultValue?: string;
}

export type ObservationInterface = {
  mainText: string;
  subText?: string;
  id: string;
};

interface CustomObservationsInterface {
  observationTitle: string;
  observationActionTitle: string;
  options: DropdownOptionType[];
  handleChangeObservations?: (payload: ObservationInterface[]) => void;
  inputs: MultiInputInterface[];
  bufferText?: string;
  existingObservations: ObservationInterface[] | null;
  inputType?: string;

  onClickEnterSaveTooltipText?: string;

  contextStateName?: string;
  saveToContext?: (a: string, b: 'COMPLAINTS' | 'EXAMINATIONS' | 'DIAGNOSIS' | 'NOTES' | 'REASON_FOR_CHANGE' | 'PROCEDURES') => void;

  showHoverEffectBorderOnInputField?: boolean;

  handleClickEditObservations?: (payload: any) => void;
}

export const CustomObservations: React.FC<CustomObservationsInterface> = ({
  observationTitle,
  observationActionTitle,
  options,
  handleChangeObservations,
  inputs,
  bufferText,
  existingObservations,
  inputType,

  onClickEnterSaveTooltipText,

  handleClickEditObservations,

  contextStateName,
  saveToContext,

  showHoverEffectBorderOnInputField,
}) => {
  const [observation, setObservation] = React.useState<string>('');
  const [observationActive, setObservationActive] = React.useState<boolean>(false);
  const [observations, setObservations] = React.useState<Array<ObservationInterface>>([]);
  const [currentId, setCurrentId] = React.useState<string | null>(null);
  const inputRef = React.useRef<HTMLDivElement | null>(null);
  const secondaryInputRef = React.useRef<HTMLInputElement | null>(null);

  const [focus, isFocused] = React.useState<boolean>(false);
  const [hover, isHovered] = React.useState<boolean>(false);

  const borderTopBottomLeft = {
    borderStyle: 'solid',
    borderColor: '#9BB4CC',
    borderTopWidth: '2px',
    borderRightWidth: '1px',
    borderBottomWidth: '2px',
    borderLeftWidth: '2px',
  };

  const borderTopBottom = {
    borderStyle: 'solid',
    borderColor: '#9BB4CC',
    borderTopWidth: '2px',
    borderRightWidth: '1px',
    borderBottomWidth: '2px',
    borderLeftWidth: '1px',
  };

  const borderTopRightBottom = {
    borderStyle: 'solid',
    borderColor: '#9BB4CC',
    borderTopWidth: '2px',
    borderRightWidth: '2px',
    borderBottomWidth: '2px',
    borderLeftWidth: '1px',
  };

  const backgroundColorOnHover = {
    backgroundColor: '#eef2f6',
  };

  const [textWidth, setTextWidth] = React.useState(0);
  React.useEffect(() => {
    const measureTextWidth = () => {
      if (inputRef.current) {
        const context = document.createElement('canvas').getContext('2d');
        if (context) {
          // Set the font properties to match the input field
          context.font = window.getComputedStyle(inputRef.current).font;
          setTextWidth(context.measureText((inputRef.current as any).value).width);
        }
      }
    };

    measureTextWidth();
  }, [observation]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    if (existingObservations) {
      setObservations(existingObservations);
      // handleChangeObservations!(existingObservations);
    }
  }, [existingObservations]);

  React.useEffect(() => {
    if (document.activeElement !== inputRef.current) {
      setObservationActive(false);
    }
  }, [document.activeElement]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    setObservationActive(true);
    // inputRef.current?.focus();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setObservationActive(false);
  };

  const handleClickDeleteObservation = (payload: string) => {
    setObservations((prev) => {
      prev = prev.filter((e) => e.id !== payload);
      if (handleChangeObservations) {
        handleChangeObservations(prev);
      }
      return prev;
    });
  };

  const handleClickEditObservation = (payload: string, id: string, completePayload: any) => {
    setObservation(payload);
    inputRef.current?.focus();
    setCurrentId(id);

    console.log();

    handleClickEditObservations?.(completePayload);
    // setObservations(observations.filter((e) => e.id !== id));
  };

  const calculateInputValues = (): string => {
    if (inputs?.[0]?.value?.length === 0) {
      return '';
    }

    return inputs.reduce((acc, curr) => {
      if (curr.value.length !== 0) {
        return `${acc + ' ' + curr.value}`.trim();
      } else {
        return '';
      }
    }, '');
  };

  const handleClickObservationValue = (payload: string) => {
    setObservation(payload);
    saveToContext && saveToContext(payload, contextStateName as 'COMPLAINTS' | 'EXAMINATIONS' | 'DIAGNOSIS' | 'NOTES' | 'REASON_FOR_CHANGE' | 'PROCEDURES');

    handleClose();
  };

  const handleSaveObservation = (inputValues: string) => {
    setObservations((prev) => {
      const subText = inputValues.length !== 0 ? (bufferText ? `${bufferText} ${inputValues}` : inputValues) : undefined;
      const mainText = observation;

      console.log('DCKJNSDKCJNDSCKJNDSKJCNDSKJCNDSKJCNJKSDCNKJDSNCKJNDSCKJDSNCKJDSNCKJDSNCKJC currentId', currentId);

      if (currentId && prev.find((e) => e.id === currentId)) {
        console.log('setObservations >>SC>S>C>SDC>S>CSD>Cds CURRENT ID', currentId);
        prev = prev.map((prevItem) => {
          if (prevItem.id === currentId) {
            return {
              ...prevItem,
              mainText,
              subText,
            };
          } else {
            return prevItem;
          }
        });
      } else {
        const indexN = prev.findIndex((item) => item.mainText === observation);

        console.log('setObservations >>SC>S>C>SDC>S>CSD>Cds indexN', indexN);

        if (indexN > -1) {
          prev = prev.map((item, index) => {
            if (indexN === index) {
              item.mainText = mainText;
              item.subText = subText;
            }
            return item;
          });
        } else {
          prev = [
            ...prev,
            {
              id: new Date().toISOString(),
              mainText,
              subText,
            },
          ];
        }
      }

      console.log('DCKJNSDKCJNDSCKJNDSKJCNDSKJCNDSKJCNJKSDCNKJDSNCKJNDSCKJDSNCKJDSNCKJDSNCKJC currentId PREV', prev);

      if (handleChangeObservations) {
        handleChangeObservations(prev);
      }
      return prev;
    });

    setCurrentId(null);

    setObservation('');
    saveToContext && saveToContext('', contextStateName as 'COMPLAINTS' | 'EXAMINATIONS' | 'DIAGNOSIS' | 'NOTES' | 'REASON_FOR_CHANGE' | 'PROCEDURES');
    for (let i = 0; i < inputs.length; i++) {
      const defaultValueInputs = inputs[i]?.defaultValue || '';
      inputs[i].onChange(defaultValueInputs);
    }
  };

  const saveObservation = (payload: string) => {
    if (observation.length === 0) {
      return;
    }

    console.log('Sdckjdsancjkasdcnkljadscnkjasdcnkljsadc', inputs?.[0].value);

    if (!inputs?.[0].value) {
      return;
    }

    const inputPayload = `${inputs[0].value} ${payload}`;

    handleSaveObservation(inputPayload);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (observation.length === 0) {
      return;
    }
    const inputValues = calculateInputValues();

    if (event.key === 'Enter') {
      setAnchorEl(null);

      handleSaveObservation(inputValues);
      // inputRef.current?.focus();
      // setAnchorEl(null);
    }

    if (event.key === 'Tab') {
      setAnchorEl(null);
    }
  };

  const _renderExtendedObservations = () => {
    if (inputs) {
      return inputs.map((item: MultiInputInterface, index) => {
        if (observation.length === 0 && !focus) return null;

        if (item.type === 'input') {
          return (
            <Box key={index} sx={{ display: 'flex', flexDirection: 'column' }}>
              {item.label && (
                <Typography variant='fontSemiBold14' color='primary' sx={{ marginLeft: '2px' }}>
                  {item.label}
                </Typography>
              )}

              <Input
                tabIndex={index + 1}
                disableUnderline={true}
                value={item.value}
                // tabIndex={1}
                inputRef={secondaryInputRef}
                onChange={({ currentTarget }) => item.onChange(currentTarget.value)}
                onFocus={() => isFocused(true)}
                onBlur={() => isFocused(false)}
                onMouseEnter={() => isHovered(true)}
                onMouseLeave={() => isHovered(false)}
                sx={{
                  boxShadow: 'none',
                  border: '1px solid #9BB4CC',
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  height: '32px',
                  padding: '0 10px',
                  '.MuiInput-input': {
                    padding: 0,
                  },
                  marginTop: '10px',
                  // maxWidth: '80px',

                  boxSizing: 'border-box',

                  '&:hover *': {
                    cursor: 'pointer',
                  },
                  ...(showHoverEffectBorderOnInputField && focus && borderTopBottom),
                  ...(showHoverEffectBorderOnInputField && !focus && hover && backgroundColorOnHover),
                }}
                onKeyDown={handleKeyPress}
                type={item.inputType}
              />
            </Box>
          );
        }
        return (
          <Box key={index} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
            {item.label && (
              <Typography variant='fontSemiBold14' color='primary' sx={{ marginLeft: '2px' }}>
                {item.label}
              </Typography>
            )}

            <FormControl
              size='small'
              tabIndex={index + 1}
              sx={{
                boxSizing: 'border-box',
                borderTopRightRadius: '5px',
                borderBottomRightRadius: observations.length !== 0 ? 0 : '5px',
                height: '32px',
                minWidth: '120px',
              }}>
              <Select
                variant='standard'
                disableUnderline={true}
                value={item.value}
                onFocus={() => isFocused(true)}
                onBlur={() => isFocused(false)}
                onMouseEnter={() => isHovered(true)}
                onMouseLeave={() => isHovered(false)}
                sx={{
                  height: '32px',
                  minWidth: '120px',
                  boxSizing: 'border-box',
                  border: '1px solid #9BB4CC',
                  borderTopRightRadius: '5px',
                  borderBottomRightRadius: '5px',
                  boxShadow: 'none',
                  '.MuiSelect-standard': { padding: '4px 0 3px 10px' },
                  '&:hover *': {
                    cursor: 'pointer',
                  },
                  ...(showHoverEffectBorderOnInputField && focus && borderTopRightBottom),
                  ...(showHoverEffectBorderOnInputField && !focus && hover && backgroundColorOnHover),
                }}
                onChange={(event: any) => {
                  item.onChange(event.target.value);
                  if (!item.disableAutoEnter) {
                    saveObservation(event.target.value);
                  }
                }}>
                {item.options?.map((e) => {
                  return (
                    <MenuItem value={e.value} key={e.value} sx={{ borderBottom: '1px solid #9BB4CC', '&:last-of-type': { borderBottom: 'none' } }}>
                      {e.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        );
      });
    }
    return null;
  };

  const _renderMenu = () => {
    if (options.length === 0) {
      return null;
    }

    return (
      <Menu
        id='basic-menu'
        autoFocus={false}
        disableAutoFocus={true}
        disableRestoreFocus={false}
        anchorEl={anchorEl}
        // onFocus={() => inputRef.current?.focus()}
        open={open}
        onKeyDown={(e) => {
          if (e.key === 'Tab') {
            secondaryInputRef.current?.focus();
          }
        }}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        elevation={2}
        sx={{ boxShadow: 'none', maxHeight: 300, '.MuiList-root': { border: '1px solid #9BB4CC', borderTop: 'none', padding: 0, width: inputRef.current?.clientWidth } }}>
        {options
          .filter((e) => e.label.toLowerCase().includes(observation.toLowerCase()))
          .map((e) => {
            return (
              <MenuItem key={e.value} sx={{ borderBottom: '1px solid #9BB4CC', '&:last-of-type': { borderBottom: 'none' } }} onClick={() => handleClickObservationValue(e.label)}>
                <ListItemIcon>
                  <RestoreIcon color='primary' />
                </ListItemIcon>

                <ListItemText>{e.label}</ListItemText>
              </MenuItem>
            );
          })}
      </Menu>
    );
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'flex',
          marginTop: '10px',
        }}>
        <FormControl variant='outlined' sx={{ flex: 1 }}>
          <Typography variant='fontSemiBold14' color='primary' sx={{ marginLeft: '2px' }}>
            {observationTitle}
          </Typography>
          <Input
            type={inputType}
            inputRef={inputRef}
            disableUnderline={true}
            startAdornment={
              !observationActive && observation.length === 0 ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <IconButton
                    sx={{ paddingLeft: 0 }}
                    id='basic-button'
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}>
                    <AddIcon />
                  </IconButton>
                </Box>
              ) : null
            }
            endAdornment={
              observation.trim().length > 0 ? (
                <InputAdornment
                  position='end'
                  sx={{
                    position: `${(textWidth < inputRef?.current?.offsetWidth! - 200 && 'absolute') || null}`,
                    left: `${textWidth + 30}px`,
                    color: 'gray',
                  }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant='fontReg12' sx={{ textWrap: 'nowrap' }} color='#696969'>
                      Press
                      <IconButton id='enter-icon-button' disabled={true} sx={{ ml: 1, mr: 1, border: '1px solid darkgray', borderRadius: 0, pr: 1, pl: 1, pt: 0, pb: 0 }}>
                        <Typography variant='fontSemiBold12' color='black'>
                          Enter
                        </Typography>
                      </IconButton>
                      to capture
                    </Typography>
                  </Box>
                </InputAdornment>
              ) : null
            }
            onClick={(e) => handleClick(e)}
            value={observation}
            onKeyDown={(e) => handleKeyPress(e)}
            onChange={({ currentTarget }) => {
              setObservation(currentTarget.value);
              saveToContext && saveToContext(currentTarget.value, contextStateName as 'COMPLAINTS' | 'EXAMINATIONS' | 'DIAGNOSIS' | 'NOTES' | 'REASON_FOR_CHANGE' | 'PROCEDURES');
            }}
            placeholder={!observationActive ? observationActionTitle : ''}
            onFocus={() => isFocused(true)}
            onBlur={() => isFocused(false)}
            onMouseEnter={() => isHovered(true)}
            onMouseLeave={() => isHovered(false)}
            sx={{
              boxShadow: 'none',
              border: '1px solid #9BB4CC',
              borderTopLeftRadius: '5px',
              borderTopRightRadius: inputs.length !== 0 ? 0 : '5px',
              borderBottomLeftRadius: observations.length !== 0 ? 0 : '5px',
              borderBottomRightRadius: observations.length !== 0 || inputs.length !== 0 ? 0 : '5px',

              marginTop: '10px',
              height: '32px',
              padding: '0 10px',
              '.MuiInput-input': {
                padding: 0,
                '&::placeholder': {
                  color: '#33425B',
                  opacity: 1,
                },
              },
              '&:hover *': {
                cursor: 'pointer',
              },
              ...(showHoverEffectBorderOnInputField && focus && borderTopBottomLeft),
              ...(showHoverEffectBorderOnInputField && !focus && hover && backgroundColorOnHover),
            }}
          />
        </FormControl>

        {_renderExtendedObservations()}
      </Box>

      {_renderMenu()}

      {observations.length !== 0 && (
        <Box
          sx={{
            borderBottom: '1px solid #9BB4CC',
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            paddingBottom: '8px',
            borderRight: '1px solid #9BB4CC',
            borderLeft: '1px solid #9BB4CC',
            padding: '2px',
          }}>
          {observations.map((e) => {
            return (
              <Chip
                key={Math.random().toString()}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Typography variant='fontReg14' color='primary'>
                      {e.mainText}
                    </Typography>
                    {e.subText && (
                      <Typography variant='fontReg12' color='primary' sx={{ marginLeft: '5px' }}>
                        {e.subText}
                      </Typography>
                    )}
                  </Box>
                }
                sx={{ margin: '5px', '&:hover': { backgroundColor: '#D3D3D3' } }}
                deleteIcon={<CloseIcon />}
                onClick={() => handleClickEditObservation(e.mainText, e.id, e)}
                onDelete={() => handleClickDeleteObservation(e.id)}
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
};
