/** @format */

import * as React from 'react';
import * as _ from 'underscore';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import { vitalsUnitConverters } from 'vitals-convertor';

import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

import PatientTrendline from './PatientTrendline';

import { RootState } from '../../../app/store';
import { BasicDetailsType } from '../../../types/Patients.types';
import { PatientVitalsReadingType } from '../../../types/PatientVitals.types';
import { PatientPrescriptionsReportType } from '../../../types/PrescriptionsGenerator.types';

import { DeCouplePatientVitalsIntoFormValueType, deCouplePatientVitalsIntoFormValue, getAllUserSelectedDateAndValues } from '../../../utils/patient-vitals-utils';

import { USER_VITALS_UNIT_MAPPING, VITALS_EGFR, VITALS_POTASSIUM } from '../../../constants/PatientVitals';

interface ViewPatientVitalReportedProps {
  prescriptions: PatientPrescriptionsReportType;

  patientBasicDetails: BasicDetailsType;

  latestVitals: PatientVitalsReadingType[];

  type: 'HEART_RATE' | 'BLOOD_PRESSURE' | 'EGFR' | 'POTASSIUM' | 'SCR' | 'WEIGHT';

  ignoreSourceType?: string;

  showTitle?: boolean;
  title?: string;

  showUnitWithTitle?: boolean;

  showDateWithValue?: boolean;
  showDateBelowValue?: boolean;

  showTooltip?: boolean;
  tooltipPlacement?: 'auto' | 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top';

  alignContent?: 'start' | 'center' | 'end';
}

const ViewPatientVitalReported: React.FunctionComponent<ViewPatientVitalReportedProps> = (props) => {
  const configState = useSelector((state: RootState) => state.config);

  const appTheme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const userUnit = configState.vitalsUnit[USER_VITALS_UNIT_MAPPING[props.type] as keyof typeof configState.vitalsUnit];

  const { titrationCycleVitals, isCriticalDownward, isCriticalUpward, isWarningDownward, isWarningUpward } = React.useMemo(() => {
    const findSeverityOfVitals = (titrationCycleVitals: DeCouplePatientVitalsIntoFormValueType[], severity: 'CRITICAL_LOW' | 'CRITICAL_HIGH' | 'WARNING_LOW' | 'WARNING_HIGH') => {
      return titrationCycleVitals.some((ele) => ele.severity === severity);
    };

    let titrationCycleVitals: DeCouplePatientVitalsIntoFormValueType[] = [];
    const treadLinedata: DeCouplePatientVitalsIntoFormValueType[] = [];

    if (props?.latestVitals?.length > 0) {
      titrationCycleVitals = props?.latestVitals?.reduce((acc: any[], item: any) => {
        if (item.type === props.type) {
          let isAddData = true;

          if (props.ignoreSourceType && item.sourceType?.toLowerCase()?.includes(props.ignoreSourceType)) {
            isAddData = false;
          }

          if (isAddData) {
            const vitals = {
              type: item.type,
              measurements: item.measurements,
              sourceType: item.sourceType,
            };

            const result = vitalsUnitConverters(JSON.parse(JSON.stringify(vitals)), userUnit);
            const decoupleVital = deCouplePatientVitalsIntoFormValue(props.type, JSON.parse(JSON.stringify({ ...item, ...result })));
            acc.push(decoupleVital);
          }
        }
        return acc;
      }, []);
    }

    titrationCycleVitals = titrationCycleVitals.sort(
      (a: DeCouplePatientVitalsIntoFormValueType, b: DeCouplePatientVitalsIntoFormValueType) => new Date(b.dateTimestamp).getTime() - new Date(a.dateTimestamp).getTime(),
    );

    console.log('titrationCycleVitalstitrationCycleVitals', titrationCycleVitals, props.type);

    const isCriticalDownward = findSeverityOfVitals(titrationCycleVitals, 'CRITICAL_LOW');
    const isCriticalUpward = findSeverityOfVitals(titrationCycleVitals, 'CRITICAL_HIGH');
    const isWarningUpward = findSeverityOfVitals(titrationCycleVitals, 'WARNING_HIGH');
    const isWarningDownward = findSeverityOfVitals(titrationCycleVitals, 'WARNING_LOW');

    return {
      treadLinedata,

      titrationCycleVitals,

      isCriticalDownward,
      isCriticalUpward,

      isWarningDownward,
      isWarningUpward,
    };
  }, [props?.latestVitals, props.type, userUnit]);

  const _renderToolTipTitle = () => {
    if (!anchorEl) {
      return null;
    }

    let treadLinedata = [];

    const LAST_7_DAYS_READING_MAP = {
      BLOOD_PRESSURE: 'bp',
      HEART_RATE: 'hr',
    };

    if (props.type === 'BLOOD_PRESSURE' || props.type === 'HEART_RATE') {
      const endDate = DateTime.now().toISO() as string;
      const startDate = DateTime.fromJSDate(new Date()).minus({ days: 6 }).toISO() as string;
      treadLinedata = getAllUserSelectedDateAndValues(startDate, endDate, titrationCycleVitals, LAST_7_DAYS_READING_MAP[props.type as keyof typeof LAST_7_DAYS_READING_MAP]);
    }

    /* Adding Missing Dates to BLOOD_PRESSURE, HEART_RATE */
    treadLinedata = treadLinedata?.filter((item: any) => !_.isEmpty(item));

    if ([VITALS_EGFR.value, VITALS_POTASSIUM.value].includes(props.type)) {
      // Removing all empty objects
      treadLinedata = props.latestVitals?.reduce((acc: any, item: any) => {
        const ele: any = !_.isEmpty(item) && deCouplePatientVitalsIntoFormValue(props.type, item);
        if (!_.isEmpty(ele)) {
          acc.push({
            ...ele,
            date: new Date(DateTime.fromJSDate(new Date(ele.date)).startOf('day').toFormat('yyyy-MM-dd 00:00:00')),
          });
        }

        return acc;
      }, []);

      treadLinedata = treadLinedata.sort((a: any, b: any) => a.date - b.date);
    }

    return <PatientTrendline id={props.type} data={treadLinedata} patientBasicDetails={props.patientBasicDetails} />;
  };

  let showAlertIcon = false;
  let alertIconColor = appTheme.palette.customColor.warning;

  if (isWarningDownward || isWarningUpward) {
    showAlertIcon = true;
    alertIconColor = appTheme.palette.customColor.warning;
  }

  if (isCriticalDownward || isCriticalUpward) {
    showAlertIcon = true;
    alertIconColor = appTheme.palette.customColor.danger;
  }

  const recentVitals: DeCouplePatientVitalsIntoFormValueType = titrationCycleVitals.length > 0 ? titrationCycleVitals[0] : ({} as DeCouplePatientVitalsIntoFormValueType);
  const recentVitalsDate = recentVitals.dateTimestamp ? DateTime.fromJSDate(new Date(recentVitals.dateTimestamp)).toRelative() : '';

  let recentVitalColor = '#3E4685';
  let recentVitalFontSize: any = 'fontReg14';

  if (recentVitals?.severity?.includes('CRITICAL')) {
    recentVitalFontSize = 'fontSemiBold14';
    recentVitalColor = appTheme.palette.customColor.danger;
  }

  if (recentVitals?.severity?.includes('WARNING')) {
    recentVitalFontSize = 'fontSemiBold14';
    recentVitalColor = appTheme.palette.customColor.warning;
  }

  return (
    <Tooltip
      title={props.showTooltip && _renderToolTipTitle()}
      onClose={() => setAnchorEl(null)}
      PopperProps={{
        placement: props.tooltipPlacement,
      }}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'transparent',
            border: 'none',
          },
        },
      }}
      enterDelay={700}>
      <Grid item xs={12} container alignContent={props.alignContent} justifyContent='center' direction='column' onMouseEnter={(event) => setAnchorEl(event.currentTarget)}>
        {props.showTitle && (
          <Typography component='div' color='#5C6A90' variant='fontReg14' sx={{ pb: 1 }}>
            <Grid container alignItems='center'>
              {props.title}
              {props.showUnitWithTitle && (
                <Typography variant='fontReg14' pl={0.2}>
                  ({userUnit})
                </Typography>
              )}
            </Grid>
          </Typography>
        )}

        <Typography align='center' variant={recentVitalFontSize} component='div' color='#3E4685'>
          <Grid container sx={{ color: recentVitalColor }} alignItems='center'>
            {showAlertIcon && <ReportProblemOutlinedIcon sx={{ marginRight: '5px', color: alertIconColor, fontSize: '20px' }} />}

            {!_.isNaN(parseFloat(recentVitals.value as string)) ? recentVitals.value : 'NA'}

            {props.showDateWithValue && recentVitalsDate && (
              <Typography variant='fontReg14' pl={1}>
                ({recentVitalsDate})
              </Typography>
            )}
          </Grid>
        </Typography>

        {props.showDateBelowValue && (
          <Typography variant='fontReg12' component='div' sx={{ mt: 1.5, color: appTheme.palette.customColor.secondary }}>
            {recentVitalsDate || ''}
          </Typography>
        )}
      </Grid>
    </Tooltip>
  );
};

ViewPatientVitalReported.defaultProps = {
  tooltipPlacement: 'top-end',
};

export default ViewPatientVitalReported;
