/** @format */

import { DateTime } from 'luxon';
import axios, { CancelTokenSource } from 'axios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

// Types and interfaces.
import { RootState } from '../../app/store';
import { PatientListNewType } from '../../types/PatientListPage.types';

import { fetchPatientsList } from '../../service/patient-list';

let alertsAxiosCancelToken: CancelTokenSource | null = null;

export interface ActivePatientListState {
  limit: number;
  pageOffset: number;

  isLoading: boolean;

  count: number;
  listData: PatientListNewType[];
}

export const FetchPatientActiveList = createAsyncThunk('FetchPatientActiveList', async ({ limit, offset, cardiologistId }: { limit: number; offset: number; cardiologistId: string | undefined }) => {
  try {
    if (alertsAxiosCancelToken) {
      alertsAxiosCancelToken.cancel();
    }

    alertsAxiosCancelToken = axios.CancelToken.source();

    const payload = await fetchPatientsList(
      {
        status: 'ACTIVE',
        limit,
        offset: offset * limit,
        flag: true,
        startDate: DateTime.now().minus({ days: 7 }).toFormat('yyyy-MM-dd'),
        endDate: DateTime.now().toFormat('yyyy-MM-dd'),
        cardiologistId,

        isEnrolmentDetails: 'false',
        isMedicalAdhrence: 'false',
        isPatientSymtoms: 'false',
        isPatientVitals: 'false',
      },
      alertsAxiosCancelToken.token,
    );

    return payload;
  } catch (err) {
    if (axios.isCancel(err)) {
      console.error(err);
    }
    throw err;
  }
});

export const activeInitialState: ActivePatientListState = {
  count: 0,
  listData: [],

  isLoading: true,

  limit: 20,
  pageOffset: 0,
};

export const activePatientListReducer = createSlice({
  name: 'Active patient list reducer',
  initialState: activeInitialState,
  reducers: {
    setPageOffset: (state, { payload }: PayloadAction<number>) => {
      state.pageOffset = Number(payload);
    },

    setLimit: (state, { payload }: PayloadAction<number>) => {
      state.pageOffset = 0;
      state.limit = Number(payload);
    },

    changeDoctorFilters: (state) => {
      state.listData = [];
      state.count = 0;
      state.pageOffset = 0;
    },

    updateActivePatientInfo: (state, { payload }: PayloadAction<PatientListNewType>) => {
      state.listData = state.listData.map((item) => {
        if (item?.basicDetails?.patientSourceId === payload?.basicDetails?.patientSourceId) {
          return { ...payload };
        }
        return item;
      });
    },

    updateDeactivePatient: (state, { payload }: PayloadAction<PatientListNewType>) => {
      state.listData = state.listData.filter((e) => e.basicDetails?.patientSourceId !== payload.basicDetails?.patientSourceId);
    },

    clearPatientListStore: (state) => {
      state = {
        count: 0,

        isLoading: false,

        listData: [],

        limit: 20,
        pageOffset: 0,
      };
      return state;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(FetchPatientActiveList.pending, (state: ActivePatientListState, action) => {
      console.log('SDJKCNSDKJCDSJKCNDSCJK LOADING');

      state.isLoading = true;
    });

    builder.addCase(FetchPatientActiveList.fulfilled, (state: ActivePatientListState, action) => {
      state.listData = action.payload?.data;
      state.count = action.payload?.count;

      console.log('SDJKCNSDKJCDSJKCNDSCJK LOADING DONE');
      state.isLoading = false;
    });

    builder.addCase(FetchPatientActiveList.rejected, (state: ActivePatientListState, action) => {
      state.isLoading = false;
    });
  },
});

export const {
  clearPatientListStore,

  updateActivePatientInfo,

  setPageOffset,
  setLimit,

  changeDoctorFilters,
  updateDeactivePatient,
} = activePatientListReducer.actions;

export const activePatientListSelector = (state: RootState) => state.activePatientList;
