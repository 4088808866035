/** @format */

import * as d3 from 'd3';
import * as React from 'react';

import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { useAppSelector } from '../../../app/hooks';

import _ from 'underscore';
import { DateTime } from 'luxon';
import { useTheme } from '@mui/material/styles';
import { ErrorBoundary } from 'react-error-boundary';
import { vitalsUnitConverters } from 'vitals-convertor';
import { ComponentErrorBoundary } from '../error/ComponentErrorBoundary';

import { vitalsChartsConfig } from './rca-resources/vitals-config';
import getApprovalMedicationTable from '../../prescriptions-generator/ApprovalMedicationTable';
import { camelize, dateFormater, filterDateWise, getFormattedObjectForVitalsUnitConverter, getMinMedianMaxDayWise, getUniqueDateData } from './rca-resources/utils';
import { addPlugins, createChart, createVitalsChart, getKccqColumns, getVitalsColumns, getWalkTestColumns, medicalEventToolTips, patientTestReportColumns } from './rca-resources/elements';

// Css
import './RCAChart.scss';

// Types
import { xAxisCorePropsInterfacePropsType } from '../../../types/RCACharts.types';

// Constants
import {
  USER_VITALS_UNIT_MAPPING,
  VITALS_BICARBONATE,
  VITALS_BLOOD_UREA_NITROGEN,
  VITALS_CALCIUM,
  VITALS_CHLORIDE,
  VITALS_EGFR,
  VITALS_GLUCOSE,
  VITALS_NTPROBNP,
  VITALS_POTASSIUM,
  VITALS_SCR,
  VITALS_SODIUM,
} from '../../../constants/PatientVitals';

const RCACharts: React.FunctionComponent<xAxisCorePropsInterfacePropsType> = (props) => {
  const vitalsUnits = useAppSelector((state: RootState) => state.config.vitalsUnit);
  const appTheme = useTheme();
  const getmasterData = useSelector((state: RootState) => state.patientSymptoms);
  const organizationMedications = useAppSelector((state) => state.orgMedications?.medications);
  const medicationColumns = getApprovalMedicationTable({ appTheme, organizationMedications });
  const vitalsColumns = getVitalsColumns({ appTheme });
  const kccqColumns = getKccqColumns({ appTheme });
  const walkTestColumns = getWalkTestColumns({ appTheme });
  const testReportColumns = patientTestReportColumns({ appTheme });

  const { sbpData, dbpData, hrData, weightData, appleWatchHrData, appleWatchSpo2Data, appleWatchStepCount, medicalEventsData } = React.useMemo(() => {
    let sbpData: any[] = [];
    let dbpData: any[] = [];
    let hrData: any[] = [];
    let weightData: any[] = [];

    let appleWatchHrData: any[] = [];
    let appleWatchSpo2Data: any[] = [];
    let appleWatchStepCount: any[] = [];
    const labReportsData: any[] = [];

    let medicalEventsData: any[] = [];

    props.data?.labReportsValues?.forEach((ele) => {
      const convertedLabReportValues = ele?.labReports?.reduce((acc: any[], e: { name: string; value: any; unit: string }) => {
        const labReprotTypes = [
          VITALS_GLUCOSE.value,
          VITALS_CALCIUM.value,
          VITALS_BLOOD_UREA_NITROGEN.value,
          VITALS_SODIUM.value,
          VITALS_BICARBONATE.value,
          VITALS_CHLORIDE.value,
          VITALS_POTASSIUM.value,
          VITALS_SCR.value,
          VITALS_EGFR.value,
          VITALS_NTPROBNP.value,
        ];

        if (!labReprotTypes.includes(e.name)) return acc;

        const formattedUnitObject = getFormattedObjectForVitalsUnitConverter(e.name, e.name, e.value, e.unit, 'MANUAL_ENTRY');
        const userUnit = vitalsUnits[USER_VITALS_UNIT_MAPPING[e.name as keyof typeof USER_VITALS_UNIT_MAPPING] as keyof typeof vitalsUnits];
        const value = vitalsUnitConverters(JSON.parse(JSON.stringify(formattedUnitObject)), userUnit);

        const isValueValid = !_.isNaN(parseFloat(value?.measurements?.[0]?.value));
        const convertedValue = isValueValid ? value.measurements[0].value : '-';

        const name = value.measurements[0].name.replace(/_/g, ' ');

        acc.push({ ...value.measurements[0], name, value: convertedValue });
        return acc;
      }, []);

      labReportsData?.push({
        date: dateFormater(ele.date),
        timestamp: ele.date,
        labReports: convertedLabReportValues,
      });
    });

    props.data?.patientVitals?.forEach((item) => {
      const startDate = DateTime.fromISO(DateTime.fromISO(props.rcaStartDate).toFormat('yyyy-MM-dd'));
      const endDate = DateTime.fromISO(DateTime.fromISO(props.rcaEndDate).toFormat('yyyy-MM-dd'));
      const itemDate = DateTime.fromISO(DateTime.fromJSDate(new Date(item.date)).toFormat('yyyy-MM-dd'));

      /* Ignoring Dates which are outside the date range given. */
      if (itemDate >= startDate && itemDate <= endDate) {
        if (item.sbp) {
          sbpData = sbpData?.concat(item?.sbp?.map((ele) => ({ date: dateFormater(item.date), timestamp: new Date(ele.timestamp).toString(), value: ele.value, severity: ele.severity })));
        }
        if (item.dbp) {
          dbpData = dbpData?.concat(item?.dbp?.map((ele) => ({ date: dateFormater(item.date), timestamp: new Date(ele.timestamp).toString(), value: ele.value, severity: ele.severity })));
        }

        if (item.hr) {
          hrData = hrData?.concat(item?.hr?.map((ele) => ({ date: dateFormater(item.date), timestamp: new Date(ele.timestamp).toString(), value: ele.value, severity: ele.severity })));
        }

        if (item.weight) {
          weightData = weightData?.concat(
            item?.weight?.map((ele) => {
              const formattedUnitObject = getFormattedObjectForVitalsUnitConverter('WEIGHT', 'WEIGHT', Number(ele.value), ele.unit, 'MANUAL_ENTRY');
              const userUnit = vitalsUnits[USER_VITALS_UNIT_MAPPING.WEIGHT as keyof typeof vitalsUnits];
              const value = vitalsUnitConverters(JSON.parse(JSON.stringify(formattedUnitObject)), userUnit);

              return {
                date: dateFormater(item.date),
                timestamp: new Date(ele.timestamp).toString(),
                value: !_.isNaN(parseFloat(value.measurements[0].value)) ? value.measurements[0].value : 0,
                unit: value.measurements[0].unit,
                severity: ele.severity,
              };
            }),
          );
        }
      }
    });

    props.data?.patientAppleWatchVitals?.forEach((item) => {
      const startDate = DateTime.fromISO(DateTime.fromISO(props.rcaStartDate).toFormat('yyyy-MM-dd'));
      const endDate = DateTime.fromISO(DateTime.fromISO(props.rcaEndDate).toFormat('yyyy-MM-dd'));
      const itemDate = DateTime.fromISO(DateTime.fromJSDate(new Date(item.date)).toFormat('yyyy-MM-dd'));

      /* Ignoring Dates which are outside the date range given. */
      if (itemDate >= startDate && itemDate <= endDate) {
        if (item.hr) {
          // appleWatchHrData = appleWatchHrData?.concat(item?.hr?.map((ele) => ({ date: dateFormater(item.date), value: ele.value, severity: ele.severity })));
          appleWatchHrData = appleWatchHrData?.concat(
            item?.hr?.map((ele) => ({ date: dateFormater(item.date), timestamp: new Date(ele.timestamp).toString(), value: ele.value, severity: ele.severity })),
          );

          // We need to get min value and max value from appleWatchHrData, but it should be day wise
          const data = getMinMedianMaxDayWise(appleWatchHrData as any);
          appleWatchHrData = data;
        }
        if (item.spo2) {
          appleWatchSpo2Data = appleWatchSpo2Data?.concat(
            item?.spo2?.map((ele) => ({ date: dateFormater(item.date), timestamp: new Date(ele.timestamp).toString(), value: ele.value, severity: ele.severity })),
          );
        }
        if (item.steps) {
          appleWatchStepCount = appleWatchStepCount?.concat(
            _.max(
              _.uniq(item?.steps).map((ele) => ({ date: dateFormater(item.date), timestamp: new Date(ele.timestamp).toString(), value: ele.value, severity: ele.severity })),
              'value',
            ),
          );
        }
      }
    });

    if (props.data?.labReportsValues) {
      const filteredLabReportsDateWise = filterDateWise(props.data?.labReportsValues, props.rcaStartDate, props.rcaEndDate);
      medicalEventsData = [...medicalEventsData, ...filteredLabReportsDateWise];
    }

    if (props.data?.medicalEventsValues) {
      const filteredMedicalEventsDateWise = filterDateWise(props.data?.medicalEventsValues, props.rcaStartDate, props.rcaEndDate);
      medicalEventsData = [...medicalEventsData, ...filteredMedicalEventsDateWise];
    }

    if (props.data?.kccqHistory) {
      const filteredKccqHistory = filterDateWise(props.data?.kccqHistory, props.rcaStartDate, props.rcaEndDate);
      medicalEventsData = [...medicalEventsData, ...filteredKccqHistory];
    }

    if (props.data?.walkTestTimeHistory) {
      const filteredWalkTestTimeHistory = filterDateWise(props.data?.walkTestTimeHistory, props.rcaStartDate, props.rcaEndDate);
      medicalEventsData = [...medicalEventsData, ...filteredWalkTestTimeHistory];
    }

    return {
      sbpData,
      dbpData,
      hrData,
      weightData,
      appleWatchHrData,
      appleWatchSpo2Data,
      appleWatchStepCount,
      medicalEventsData,
    };
  }, [
    props.data?.labReportsValues,
    props.data?.patientVitals,
    props.data?.patientAppleWatchVitals,
    props.data?.medicalEventsValues,
    props.data?.kccqHistory,
    props.data?.walkTestTimeHistory,
    props.rcaStartDate,
    props.rcaEndDate,
    vitalsUnits,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const medicalEventToolTip = (data: { date: any; [value: string]: any[] }) => {
    return medicalEventToolTips(data, getmasterData, medicationColumns, vitalsColumns, kccqColumns, walkTestColumns, testReportColumns);
  };

  // set the dimensions and margins of the graph
  const width = props.dimensions!.width - props.margin!.left - props.margin!.right;
  // const height = props.dimensions!.height - props.margin!.top - props.margin!.bottom;

  const activeGraphsHeights: number[] = [0];

  const { totalHeight, component } = React.useMemo(() => {
    const options = {
      yAxisRange: {
        type: props.range ? 'MANUAL' : 'AUTO',
        max: props.upperBound,
        min: props.lowerBound,
      },
      yAxisTicks: props.yAxisTicks,
      xAxisTickSize: props.xAxisTickSize,
      yAxisTickSize: props.yAxisTickSize,
      timeFormat: props.timeFormat,
      xAxisNice: props.xAxisNice,
      yAxisNice: props.yAxisNice,
      xAxisPosition: props.xAxisPosition,
      rangeXAxisOffset: props.rangeXAxisOffset,
      rangeYAxisOffset: props.rangeYAxisOffset,
      scale: props.scale,
    };

    // Getting all unique dates for rca from start to end date
    const uniqueDateData = getUniqueDateData(props.rcaStartDate, props.rcaEndDate);

    // Creating a chart
    const xAxisCore = createChart(props, width, uniqueDateData, options);

    const dateGroupMedicalEvents = _.groupBy(medicalEventsData, (ele) => ele.date);

    const maxMedicalEventsInGraph: number = Object.keys(dateGroupMedicalEvents).reduce((acc, dateKey) => {
      if (new Date(dateKey).getTime() >= new Date(props.rcaStartDate).getTime() && new Date(dateKey).getTime() <= new Date(props.rcaEndDate).getTime()) {
        const count = dateGroupMedicalEvents[dateKey].length || 0;
        return Math.max(count, acc);
      } else {
        return acc;
      }
    }, 1);

    // Getting vitals chart config object
    let vitals = vitalsChartsConfig(props, xAxisCore, vitalsUnits, maxMedicalEventsInGraph, {
      sbpData,
      dbpData,
      hrData,
      weightData,
      appleWatchHrData,
      appleWatchSpo2Data,
      appleWatchStepCount,
      medicalEventsValues: medicalEventsData,
    });

    // Filtering data for re-drawing the RCA Chart completly (on toggleGraphs state)
    // Getting the Graphs height as well
    let totalHeight = 0;
    vitals = vitals.filter((item) => {
      const toggledGraph = props.toggleGraphs[camelize(item.chartName.toLowerCase())];
      if (toggledGraph) {
        totalHeight += item.height;
        activeGraphsHeights.push(totalHeight);
      }

      return toggledGraph;
    });

    vitals.forEach((item, index) => {
      const corePluginName = item.chartName + 'core';

      const zoomCallback = (xAxisScale: any, yAxisScale: any, zoomRectElement: any) => {
        Object.keys(xAxisCore.plugins)?.forEach((pluginName) => {
          const pluginInstance = xAxisCore.plugins[pluginName];
          if (pluginName.startsWith(item.chartName) && pluginInstance.zoomBehavior) {
            pluginInstance.zoomBehavior(xAxisScale, yAxisScale, zoomRectElement);
          }
        });
      };

      createVitalsChart(props, corePluginName, vitalsUnits, item, index, xAxisCore, width, item.height, activeGraphsHeights, zoomCallback);

      addPlugins(props, item, corePluginName, xAxisCore, width, item.height, medicalEventToolTip);

      // xAxisCore.core.createClipPath();
    });

    d3.select('.svg-element').append('use').attr('xlink:href', '#corexaxis');
    // d3.select('.svg-element').append('use').attr('xlink:href', '#MEDICAL-EVENTScore');
    d3.selectAll('foreignObject').attr('x', '-250');

    return {
      totalHeight,
      component: <></>,
    };
  }, [activeGraphsHeights, appleWatchHrData, appleWatchSpo2Data, appleWatchStepCount, dbpData, hrData, medicalEventToolTip, medicalEventsData, props, sbpData, vitalsUnits, weightData, width]);

  return (
    <ErrorBoundary FallbackComponent={ComponentErrorBoundary}>
      <div ref={props.rcaChartRef} id={props.id} style={{ width: '100%', height: `${totalHeight + 36}px`, borderBottom: '1px solid #B3C6D8' }}>
        {component}
      </div>
    </ErrorBoundary>
  );
};

RCACharts.defaultProps = {
  margin: { top: 10, right: 30, bottom: 30, left: 60 },
};

export default RCACharts;
